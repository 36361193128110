<template>
  <div class="fill_in">
    <!-- 基本信息 -->
    <div class="essential_information_title">
      基本信息
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>投诉对象</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_rights">
        <input type="text" class="ipt" @focus="obtain" @click="lose" @input="Input_Events" v-model="Input_Value"
          placeholder="请选择投诉对象">
        <!-- 取消 -->
        <div class="cancel" v-if="disshow ==true" @click="cancel">取消</div>
        <!-- 放大镜 -->
        <div class="magnifier"><img src="@/assets/img/search_icon.png" alt=""></div>
        <!-- 热门 -->
        <div class="focus_on" v-if="isshow == true">
          <div class="focus_on_top">热门投诉对象</div>
          <ul class="focus_on_bottom">
            <li v-for="(item,index) in Hot_lists" :key="index" v-if="index < 9"><span
                @click="Choose_Hot(item.name,item.id)" v-if="index < 9">{{item.name}}</span></li>
          </ul>
        </div>
        <!-- 输入展示 -->
        <div class="Input_List" v-if="isList == false">
          <div class="Input_Lists" v-for="(item,index) in Lists" :key="index">
            <div @click="Choose_Input_List(item.name,item.id)">{{item.name}}</div>
          </div>
          <div class="Input_Listss" @click="Add_Obj">
            <div>可直接添加{{Input_Value}}</div>
            <div>
              <img src="@/assets/img/Add_List.png" alt="">添加
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>投诉缘由</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt" v-model="Input_Value1" placeholder="简单叙述不超过20个字">
      </div>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>投诉诉求</span>
        <span></span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt" v-model="Input_Value2" placeholder="简单叙述不超过20个字">
      </div>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>涉诉单号</span>
        <img src="@/assets/img/xingzhuang7.png" alt="">
        <span style="color: #ff4a4a;">＊</span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt" v-model="Input_Value3" placeholder="订单号、交易平台帐号等(没有可填无)">
      </div>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>涉诉金额</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt_num" v-model="Input_Value4" placeholder="没有可填0">
        <pre style="display:inline-block;">  元</pre>
      </div>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>手机号码</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt" v-model="Input_Value5">
      </div>
    </div>
    <div class="Detailed_statement_title">
      详细陈述<span>＊</span>
    </div>
    <div class="Object_complaint">
      <div class="Object_complaint_left">
        <span>陈述标题</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <input type="text" class="ipt" v-model="Input_Value6" placeholder="简单叙述不超过20个字">
      </div>
    </div>
    <div class="Object_complaint N_Object_complaint">
      <div class="Object_complaint_left">
        <span>投诉详情</span>
        <span>＊</span>
      </div>
      <div class="Object_complaint_right">
        <textarea name="" id="" class="textarea" cols="30" v-model="Input_Value7" placeholder="请详细阐述不少于20字的投诉事由"
          rows="10"></textarea>
      </div>
    </div>
    <div class="Evidential_materials">
      证据材料<img src="@/assets/img/xingzhuang7.png" alt="">
    </div>
    <el-upload class="Max_Wdith" action="https://tsadmin.qudong.com/ApiUpload/upload_many" :headers="headers"
      :before-upload="beforeRef" :on-success="successAgreement" multiple name="file[]" list-type="picture-card"
      :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <div class="be_careful">
      <div>注:</div>
      <div>1. 请依据事实投诉，禁止上传含有违反国家法律法规及侮辱、谩骂、诋毁、侵害他人隐私的文字、图片和视频内容。 </div>
      <div>2. 标有*号处为必填项，标有<img style="display:inline-block;width:14px;height:16px;"
          src="@/assets/img/xingzhuang7.png" />为隐私项仅投诉人、被诉人、平台三方可见。</div>
    </div>

    <div class="Submit" @click="Submit">提交</div>
  </div>
</template>

<script>
import {
  Complaint_object_list,
  Add_Complaint_object_list,
  Complaint_application,
} from "../../api/api.js";

let token = localStorage.getItem("token");
let url1 = ""; //传多图
export default {
   metaInfo(){
     return{
        title: '我要投诉',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      isshow: false,
      disshow: false,
      isList: true,
      fileList: [],
      headers: {
        token: token,
      },
      dialogImageUrl: "",
      dialogVisible: false,
      agreement_img: [],
      url1: "",
      Hot_lists: [], //热门投诉对象
      Hot_name: "", //热门name
      Obj_id: "", //热门id
      Lists: [], //输入框搜索获取的li
      Input_Value: "", //投诉对象的值
      Input_Value1: "", //输入框的值
      Input_Value2: "", //输入框的值
      Input_Value3: "", //输入框的值
      Input_Value4: "", //输入框的值
      Input_Value5: "", //输入框的值
      Input_Value6: "", //输入框的值
      Input_Value7: "", //输入框的值
    };
  },
  methods: {
    // 投诉对象input被激活状态
    obtain() {
      this.isshow = true;
    },
    // 投诉对象input不被激活
    lose() {
      this.disshow = true;
    },
    // 取消
    cancel() {
      this.isshow = false;
      this.disshow = false;
      this.isList = true;
    },
    // 图片上传操作前判断，限制大小
    beforeRef(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG || !isLt2M) {
        this.$message.error("上传文件只能是图片格式，且大小不能超过2M");
      }
      return isJPG, isLt2M;
    },
    // 请求图片成功
    successAgreement(res) {
      // //console.log(res);
      url1 = url1.concat(res.data + ",");
      this.url1 = url1;
      //console.log(url1);
    },
    // 删除图片
    handleRemove(file, fileList) {
      // //console.log(file, fileList);
    },
    // 预览图片
    handlePictureCardPreview(file) {
      // //console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 热门投诉列表展示
    Hot_list() {
      Complaint_object_list({}).then((res) => {
        this.Hot_lists = res.data.data.data;
      });
    },
    // 点击热门投诉事件
    Choose_Hot(name, id) {
      this.Input_Value = name;
      this.isshow = false;
      this.disshow = false;
      this.isList = true;
      this.Obj_id = id;
    },
    // 投诉对象输入事件
    Input_Events() {
      if (this.Input_Value != "") {
        this.isshow = false;
        this.isList = false;
      } else {
        this.isshow = true;
        this.isList = true;
      }
    },
    // 点击输入列表
    Choose_Input_List(name, id) {
      this.Input_Value = name;
      this.isList = true;
      this.disshow = false;
      this.Obj_id = id;
    },
    // 添加投诉对象
    Add_Obj() {
      Add_Complaint_object_list({ name: this.Input_Value }).then((res) => {
        this.Obj_id = res.data.data;
        this.isshow = false;
        this.isList = true;
        this.disshow = false;
      });
    },
    // 提交
    Submit() {
      let Obj_id = this.Obj_id;
      let Input_Value1 = this.Input_Value1;     
      let Input_Value2 = this.Input_Value2;
      let Input_Value3 = this.Input_Value3;
      let Input_Value4 = this.Input_Value4;
      let Input_Value5 = this.Input_Value5;
      let Input_Value6 = this.Input_Value6;
      let Input_Value7 = this.Input_Value7;
      let url1=this.url1;
      // const isphone = this.verifyPhoneNum(Input_Value5);
      if (!Obj_id) {
        this.$message.error("投诉对象不能为空");
      } else {
        if (!Input_Value1) {
          this.$message.error("投诉缘由不能为空");
        } else {
          if (!Input_Value3) {
            this.$message.error("涉诉单号不能为空");
          } else {
            if (!Input_Value4) {
              this.$message.error("涉诉金额不能为空");
            } else {
              if (!Input_Value5) {
                this.$message.error("手机号码不能为空");
              } else {
                if (!Input_Value6) {
                  this.$message.error("陈述标题不能为空");
                } else {
                  if (!Input_Value7) {
                    this.$message.error("投诉详情不能为空");
                  } else {
                    const isphone = this.verifyPhoneNum(Input_Value5);
                    if (isphone == true) {
                      Complaint_application({
                        complaint_business_id:Obj_id,
                        complaint_reason:Input_Value1,
                        complaint_appeal:Input_Value2,
                        complaint_order_number:Input_Value3,
                        complaint_price:Input_Value4,
                        mobile:Input_Value5,
                        statement_title:Input_Value6,
                        complaint_details:Input_Value7,
                        evidential_materials:url1,
                      }).then((res) => {
                       //console.log(res.data.code);
                       if(res.data.code == 200){
                          this.$message.success(res.data.msg);
                         setTimeout("location.href='/'",1000);
                       }else{
                          this.$message.error(res.data.msg);
                       }
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    success(m) {
      layer.msg(m, { icon: 1 });
    },
    error(m) {
      layer.msg(m, { icon: 0 });
    },
    // 验证手机正则
    verifyPhoneNum(phoneNum) {
      const myreg = /^[1][3,4,5,7,6,8][0-9]{9}$/;
      if (!myreg.test(phoneNum)) {
        this.$message.error("手机号码格式不对");
        // 不对返回
        return false;
      } else {
        // 且true
        return true;
      }
    },
  },
  watch: {
    Input_Value: {
      handler(newVal, oldVal) {
        // //console.log("监听值变化", newVal);
        if (newVal != "") {
          Complaint_object_list({ search: newVal }).then((res) => {
            // //console.log(res.data.data.data);
            let Lists = res.data.data.data;
            this.Lists = Lists;
          });
        } else {
          this.Lists = [];
        }
      },
    },
  },
  mounted() {
    this.Hot_list();
  },
};
</script>

<style scoped>
.fill_in {
  width: 940px;
  background: #fff;
  margin-left:20px;
  padding: 30px 0px 40px 40px;
}
.essential_information_title {
  font-size: 22px;
}
.ipt {
  width: 540px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  padding-left: 15px;
}
.Object_complaint {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Object_complaint_left {
  width: 95px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.Object_complaint_left img {
  width: 14px;
  height: 16px;
}
.Object_complaint_rights {
  position: relative;
}

.Object_complaint_left span:nth-child(1) {
  color: black;
}
.Object_complaint_left span:nth-child(2) {
  color: #ff4a4a;
}
.ipt_num {
  width: 100px;
  height: 40px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding-left: 15px;
}
.Detailed_statement_title {
  font-size: 22px;
  margin-top: 50px;
}
.Detailed_statement_title span {
  color: #ff4a4a;
}
.N_Object_complaint {
  align-items: end;
}
.textarea {
  width: 540px;
  height: 240px;
  resize: none;
  padding-left: 15px;
  border: 1px solid #e8e8e8;
  padding-top: 10px;
}
.Evidential_materials {
  font-size: 22px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.Evidential_materials img {
  width: 14px;
  height: 16px;
}
>>> .el-upload--picture-card {
  background: url(../../assets/img/upload_img.png) no-repeat center;
  border: none;
}
>>> .el-icon-plus {
  display: none;
}
.Max_Wdith {
  width: 600px;
  margin-left: 115px;
  margin-top: 20px;
  margin-bottom: 40px;
}
>>> .el-upload-list__item {
  margin-right: 30px;
}
.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
.be_careful {
  margin-left: 115px;
  width: 580px;
  color: #666666;
  font-size: 16px;
}
.Submit {
  width: 160px;
  height: 48px;
  background: #1f72d9;
  border-radius: 10px;
  color: #fefeff;
  font-size: 18px;
  text-align: center;
  line-height: 48px;
  margin: 50px auto 0 auto;
  margin: 50px 0px 0px 305px;
  cursor: pointer;
}

/* 投诉对象被激活 */
.focus_on {
  width: 500px;
  height: 206px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  position: absolute;
  background: #ffffff;
  top: 50px;
}
.focus_on_top {
  font-size: 18px;
  color: #333333;
  margin-left: 10px;
  margin-top: 20px;
}
.focus_on_bottom {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-right: 50px;
}
.focus_on_bottom li {
  border: 1px solid #999999;
  border-radius: 18px;
  margin-left: 25px;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
}
/* 取消 */
.cancel {
  position: absolute;
  right: 65px;
  top: 11px;
  color: #999999;
  font-size: 14px;
  cursor: pointer;
}
/* 放大镜 */
.magnifier {
  position: absolute;
  right: 0;
  top: 1px;
  width: 50px;
  height: 40px;
  background: #d9d9d9;
}
.magnifier img {
  width: 18px;
  height: 18px;
  margin: 11px auto;
}
.Input_List {
  position: absolute;
  top: 50px;
}
.Input_Lists {
  width: 491px;
  height: 40px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  padding-left: 15px;
  cursor: pointer;
  background: #fff;
}
.Input_Listss {
  width: 491px;
  height: 40px;
  border: 1px solid #e8e8e8;
  line-height: 40px;
  padding-left: 15px;
  cursor: pointer;
  background: #e8e8e8;
  display: flex;
  justify-content: space-between;
}
.Input_Listss img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.Input_Listss div:nth-child(2) {
  display: flex;
  align-items: center;
  margin-right: 15px;
  color: #1f72d9;
}
</style>